//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment";
import $ from 'jquery'
import func from "@/utils/decoded"
import {fetchSyncDataLatest, syncData} from "@/api/admin";
import {Message} from "element-ui";

export default {
  name: 'Login',
	data () {
		return {
			syncDate: '',
			syncStatus: '',
			syncBy: '',
			onSync: false,
			syncForm: {
				customDate: '',
			},
			privilege: {read: false, write: false, report: false}
		}
	},
	async mounted() {
		this.$store.state.loading = true
		const check = func.checkMenu(this.$route.path);
		if (check && check.length > 0) {
			this.privilege = check[0];
		}
		try {
			await new Promise((resolve, reject) => {
				fetchSyncDataLatest().then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						this.syncDate = data.sync_date
						this.syncStatus = data.sync_status
						this.syncBy = data.sync_by
						resolve()
					}
				}).catch(error => {
					this.errorMsg = error
					console.log(error)
					reject(error)
				})
			})
		} finally {
			this.$store.state.loading = false
		}
	},
	created() {
		document.title = this.$route.name + " : ONE Tracemoney"
		const check = func.checkMenu(this.$route.path)
		if (check && check.length === 0) {
			this.$router.push({path: '/404'})
		}
	},
	methods: {
		async syncNow() {
			this.onSync = true
			let check = ''
			await syncData().then(response => {
				check = response.header
				const data = response.body
				this.syncDate = data.sync_date
				this.syncStatus = data.sync_status
				this.syncBy = data.sync_by
			}).catch(error => {
				this.errorMsg = error;
				this.$session.start();
				this.syncDate = moment().format('DD/MM/YYYY HH:mm:ss');
				this.syncStatus = 'FAIL';
				this.syncBy = this.$session.get('fullname');
				console.log(error)
			})
			if (check.error === 'N') {
				if (check.message !== 'success') {
					Message({
						message: check.message,
						type: 'warning',
						duration: 5 * 1000,
					});
				} else {
					Message({
						message: 'สำเร็จ',
						type: 'success',
						duration: 5 * 1000,
					});
				}
			} else {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
			}
			this.onSync = false
			$('#cBody').hide();
			$('#cBody').fadeIn('slow');
		},
		async customSync() {
			this.onSync = true
			let check = ''
			const param = {
				customDate: ''
			}
			if (this.syncForm.customDate !== "") {
				param.customDate = moment(this.syncForm.customDate).format('YYYY-MM-DD');
				await syncData(param).then(response => {
					check = response.header
					const data = response.body
					this.syncDate = data.sync_date
					this.syncStatus = data.sync_status
					this.syncBy = data.sync_by
				}).catch(error => {
					this.errorMsg = error;
					this.$session.start();
					this.syncDate = moment().format('DD/MM/YYYY HH:mm:ss');
					this.syncStatus = 'FAIL';
					this.syncBy = this.$session.get('fullname');
					console.log(error)
				})
				if (check.error === 'N') {
					if (check.message !== 'success') {
						Message({
							message: check.message,
							type: 'warning',
							duration: 5 * 1000,
						});
					} else {
						Message({
							message: 'สำเร็จ',
							type: 'success',
							duration: 5 * 1000,
						});
					}
				} else {
					Message({
						message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
						type: 'error',
						duration: 5 * 1000,
					})
				}
			}
			this.onSync = false
			$('#cBody').hide();
			$('#cBody').fadeIn('slow');
		},
		goBack() {
			this.$router.push({path: '/'})
		}
	}
}
